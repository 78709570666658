import { captureException } from '@sentry/sveltekit';

/**
 * Get all purchased courses for the current user
 * @param {import('@supabase/supabase-js').SupabaseClient} supabase - Supabase client
 * @returns {Promise<string[]>} - Array of course IDs
 */
export async function get_purchased_courses(supabase) {

    let pc = [];
    try {
        let { data, error } = await supabase
            .from('User_Purchases')
            .select('course_id')
            .eq('status', 'purchased');

        if (error) {
            // TODO: update global error stack
            captureException(error, {
                extra: {
                    message: 'Suppabase error getting purchased courses',
                    supabase: {
                        table: 'User_Purchases',
                        query: 'select course_id from User_Purchases where status = "purchased"'
                    }
                }
            });
            console.error(error);
            return [];
        }

        pc = data?.map(purchase => purchase.course_id) || [];

    } catch (error) {
        // TODO: update global error stack
        captureException(error, {
            extra: {
                message: 'Error getting purchased courses',
                supabase: {
                    table: 'User_Purchases',
                    query: 'select course_id from User_Purchases where status = "purchased"'
                }
            }
        });
        console.error(error);
    }

    return pc;
}

/**
 * Patch purchased courses to the courses array
 * @param {Course[]} courses - Array of courses
 * @param {import('@supabase/supabase-js').SupabaseClient} supabase - Supabase client
 * @returns {Promise<Course[]>} - Array of courses
 */
export const patch_purchased_courses = async (courses, supabase) => {
    let purchased_courses = await get_purchased_courses(supabase);
    return courses.map(course => {
        course.purchased = purchased_courses.includes(course.id);
        return course;
    });
}


/**
 * Get all purchased worksheets for the current user
 * @param {import('@supabase/supabase-js').SupabaseClient} supabase - Supabase client
 * @returns {Promise<string[]>} - Array of worksheet IDs
 */
export async function get_purchased_worksheets(supabase) {
    let pc = [];
    try {
        let { data, error } = await supabase
            .from('User_Worksheet_Purchases')
            .select('worksheet_id')
            .eq('status', 'purchased');

        if (error) {
            // TODO: update global error stack
            console.error(error);
            captureException(error, {
                extra: {
                    message: 'Suppabase error getting purchased worksheets',
                    supabase: {
                        table: 'User_Worksheet_Purchases',
                        query: 'select worksheet_id from User_Worksheet_Purchases where status = "purchased"'
                    }
                }
            });
            return [];
        }

        pc = data?.map(purchase => purchase.worksheet_id) || [];

    } catch (error) {
        // TODO: update global error stack
        console.error(error);
        captureException(error, {
            extra: {
                message: 'Error getting purchased worksheets',
                supabase: {
                    table: 'User_Worksheet_Purchases',
                    query: 'select worksheet_id from User_Worksheet_Purchases where status = "purchased"'
                }
            }
        });
    }

    return pc;
}

export async function patch_purchased_worksheets(worksheets, supabase) {
    let purchased_worksheets = await get_purchased_worksheets(supabase);
    return worksheets.map(worksheet => {
        worksheet.purchased = purchased_worksheets.includes(worksheet.id);
        return worksheet;
    });
}

export async function patch_purchased_bundles(bundles, courses, worksheets) {
    return bundles.map(bundle => {
        bundle.items = bundle.items.map(item => {
            if (item.type === 'course') {
                item.purchased = courses.some(c => c.id === item.id && c.purchased);
            } else if (item.type === 'worksheet') {
                item.purchased = worksheets.some(w => w.id === item.id && w.purchased);
            }
            return item;
        });

        bundle.purchased = bundle.items.every(item => item.purchased);
        return bundle;
    });
}