<script>
    import { browser } from "$app/environment";
    import { onNavigate } from "$app/navigation";
    import { cookie_consent_marketing } from "./stores/globals";

    const install_facebook = () => {
        if (!browser) return;
        // if (!$cookie_consent_marketing) return;

        // console.log('install_facebook', $cookie_consent_marketing);

        // @ts-ignore
        !function(f,b,e,v,n,t,s){
            if (f.fbq) return;
            
            n=f.fbq=function(){
                n.callMethod ? n.callMethod.apply(n,arguments) : n.queue.push(arguments)
            };
            if(!f._fbq) f._fbq=n; 
            n.push=n;
            n.loaded=!0;
            n.version='2.0';
            n.queue=[];
            t=b.createElement(e);
            t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)
        }(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');
        window.fbq('init', '1032062254574213');
        window.fbq('track', 'PageView');
    }

    onNavigate((navigation) => {
        navigation.complete.then(() => {
            if (!browser) return;
            // if (!$cookie_consent_marketing) return;
            // console.log('facebook pageview');
            window.fbq('track', 'PageView');
        });
        
    });

    install_facebook();
</script>
