<script>
    import Button from './Button.svelte';
    import Prose from './Prose.svelte';

    import { browser } from '$app/environment';
    import { page } from '$app/stores';
    import Icon from './Icon.svelte';
    import { logEvent } from './utils/logs';
    import { discount_data, isProd } from './stores/globals';
    import { onNavigate } from '$app/navigation';
    import { slide } from 'svelte/transition';

    export let code;

    let course_slug = $page.params?.course || null;

    $: is_course_purchased =
        course_slug &&
        $page.data.session &&
        $page.data.courses?.some(
            (course) => course.slug === course_slug && course.purchased
        );

    let worksheet_slug = $page.params?.worksheet || null;
    $: is_worksheet_purchased =
        worksheet_slug &&
        $page.data.session &&
        $page.data.worksheets?.some(
            (worksheet) =>
                worksheet.slug === worksheet_slug && worksheet.purchased
        );

    let is_closed =
        browser &&
        window.localStorage.getItem(`discount_banner_${code}`) === 'closed';

    $: active = $discount_data.active && $discount_data.code === code;

    const handle_close = () => {
        // console.log('handle_close');
        if (active && !is_closed && (!is_course_purchased || !is_worksheet_purchased)) {
            logEvent('discount_banner_closed');
            is_closed = true;
            window.localStorage.setItem(`discount_banner_${code}`, 'closed');
        }
    };

    onNavigate(() => {
        handle_close();
    });
</script>

{#if active && !is_closed && (!is_course_purchased || !is_worksheet_purchased)}
    <div
        transition:slide
        class="fixed bottom-safe-offset-4 left-1/2 -translate-x-1/2 z-10 container px-4 xs:px-8"
    >
        <div class="bg-green-600 py-2 rounded-xl pl-4 pr-16 relative shadow-md">
            <Prose class="text-center" fluid>
                <p class="!m-0">
                    <slot />
                </p>
            </Prose>
            <Button
                sm
                class="!absolute top-1/2 -translate-y-1/2 right-2"
                animated={false}
                on:click={handle_close}><Icon times /></Button
            >
        </div>
    </div>
{/if}
