<script>
    import Button from './Button.svelte';
    import PageSection from './PageSection.svelte';
    import Prose from './Prose.svelte';

    import { browser } from '$app/environment';
    import { page } from '$app/stores';
    import Icon from './Icon.svelte';
    import { logEvent } from './utils/logs';
    import { discount_data, isProd } from './stores/globals';
    import { slide } from 'svelte/transition';
    import { onMount } from 'svelte';
    import Auth from './Auth.svelte';

    export let code;

    let course_slug = $page.params?.course || null;
    let worksheet_slug = $page.params?.worksheet || null;

    $: is_course_purchased =
        course_slug &&
        $page.data.session &&
        $page.data.courses?.some(
            (course) => course.slug === course_slug && course.purchased
        );

    $: is_worksheet_purchased =
        worksheet_slug &&
        $page.data.session &&
        $page.data.worksheets?.some(
            (worksheet) =>
                worksheet.slug === worksheet_slug && worksheet.purchased
        );

    let is_closed =
        browser &&
        (window.localStorage.getItem(`discount_banner_${code}`) === 'closed' ||
            window.localStorage.getItem(`discount_banner_${code}`) ===
                'applied');

    const handle_close = () => {
        // console.log('handle_close');
        logEvent('discount_banner_closed', { code });
        is_closed = true;
        window.localStorage.setItem(`discount_banner_${code}`, 'closed');
    };

    const handle_apply = () => {
        if (!code_data?.active) {
            console.error('Discount code is not active');
            return;
        }

        logEvent('discount_banner_applied');
        is_closed = true;
        window.localStorage.setItem(`discount_banner_${code}`, 'applied');
        window.localStorage.setItem(`discount_code`, code);

        $discount_data = { ...code_data };
    };

    let code_data;

    onMount(() => {
        if (!is_closed) {
            fetch('/api/paddle/check_code?code=' + code)
                .then((res) => res.json())
                .then((dis) => {
                    code_data = dis;
                });
        }
    });
</script>

{#if !$discount_data.active && code_data?.active && !is_closed && !is_course_purchased && !is_worksheet_purchased}
    <div
        transition:slide
        class="fixed bottom-safe-offset-4 left-1/2 -translate-x-1/2 z-10 container px-4"
    >
        <div class="bg-green-700 dark:bg-green-800 py-2 rounded-xl pl-4 pr-16 relative shadow-md">
            <Prose class="text-center" fluid>
                <p class="!m-0">
                    <slot />
                    <Button outline sm on:click={handle_apply}>Apply</Button>
                </p>
            </Prose>
            <Button
                sm
                class="!absolute top-1/2 -translate-y-1/2 right-2"
                animated={false}
                on:click={handle_close}><Icon times /></Button
            >
        </div>
    </div>
{/if}
