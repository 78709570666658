import { browser } from '$app/environment';
import {
    PUBLIC_SUPABASE_ANON_KEY,
    PUBLIC_SUPABASE_URL
} from '$env/static/public';
import { patch_purchased_bundles, patch_purchased_courses, patch_purchased_worksheets } from '$lib/utils/get_purchases';
import { createSupabaseLoadClient } from '@supabase/auth-helpers-sveltekit';


// export const prerender = false;
// export const ssr = false;
export const load = async ({ fetch, data, depends }) => {
    depends('supabase:auth');

    const supabase = createSupabaseLoadClient({
        supabaseUrl: PUBLIC_SUPABASE_URL,
        supabaseKey: PUBLIC_SUPABASE_ANON_KEY,
        event: { fetch },
        serverSession: data.session
    });

    const {
        data: { session }
    } = await supabase.auth.getSession();

    let { courses, worksheets, bundles } = data;

    if (browser && session) {
        courses = await patch_purchased_courses(courses, supabase);
        worksheets = await patch_purchased_worksheets(worksheets, supabase);
        bundles = await patch_purchased_bundles(bundles, courses, worksheets);
    }
    
    return {...data, supabase, session, courses, worksheets, bundles };
};
